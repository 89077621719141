


























































import Vue from 'vue';

import XLSX from 'xlsx';
import { saveAs } from 'file-saver';

import labels from './labels';

export default Vue.extend({
    name: 'StatisticsTable',
    props: {
        items: {
            type: Array,
            required: true
        },
        total: {
            type: Object,
            default() {
                return {};
            }
        },
        order: {
            type: Array
        }
    },
    data() {
        return {
            labels,
            localData: [] as any,
            filter: {
                name: '' as any,
                type: 'down'
            }
        };
    },
    mounted() {
        this.filter.name = this.order[0];
        this.localData = [...this.items];
        this.filtered();
    },
    methods: {
        setFilter(name, type) {
            this.filter = {
                name,
                type
            };

            this.filtered();
        },
        filtered() {
            this.localData.sort((a: any, b: any) => {
                if (this.filter.type === 'up') {
                    if (a[this.filter.name] > b[this.filter.name]) {
                        return 1;
                    } else if (b[this.filter.name] > a[this.filter.name]) {
                        return -1;
                    }

                    return 0;
                }

                if (a[this.filter.name] < b[this.filter.name]) {
                    return 1;
                } else if (b[this.filter.name] < a[this.filter.name]) {
                    return -1;
                }

                return 0;
            });
        },
        downloadTable() {
            const wb = XLSX.utils.book_new();

            wb.Props = {
                Title: 'Table',
                Subject: 'Table',
                Author: 'Zaymigo',
                CreatedDate: new Date()
            };

            wb.SheetNames.push('Table');

            const titles = [] as any;

            this.order.forEach((item: any) => {
                titles.push(this.labels[item]);
            });

            const wsData = [titles] as any;


            this.localData.forEach((item: any) => {
                const line = [] as any;

                this.order.forEach((title: any) => {
                    if (title === 'target_events') {
                        if (item[title].indexOf('issue') > -1) {
                            line.push('Выдача');
                        } else {
                            line.push('Заявка');
                        }
                    } else {
                        line.push(item[title]);
                    }
                });

                wsData.push(line);
            });

            const TotalKeys = Object.keys(this.total);
            if (TotalKeys.length > 0) {
                const line = ['Итого'] as any;

                this.order.forEach((title: any) => {
                    if (typeof this.total[title] !== 'undefined') {
                        line.push(this.total[title]);
                    }
                });

                wsData.push(line);
            }

            wb.Sheets.Table = XLSX.utils.aoa_to_sheet(wsData);

            const wbout = XLSX.write(wb, {
                bookType: 'xlsx',
                type: 'binary'
            });

            function s2ab(s) {
                const buf = new ArrayBuffer(s.length); // convert s to arrayBuffer
                const view = new Uint8Array(buf);  // create uint8array as viewer
                for (let i = 0; i < s.length; i++) {
                    /* tslint:disable:no-bitwise */
                    view[i] = s.charCodeAt(i) & 0xFF;
                    /* tslint:enable:no-bitwise */
                } // convert to octet
                return buf;
            }

            saveAs(new Blob([s2ab(wbout)], {type: 'application/octet-stream'}), 'table.xlsx');
        }
    },
    watch: {
        items(val) {
            this.localData = [...val];
            this.filtered();
        }
    }
});
