





























































































import Vue from 'vue';

import RCP from '@/api/RCP.ts';

import ViewChart from '@/components/statistics/Chart/View.vue';
import StatisticsTable from '@/components/statistics/Table.vue';
import DatePicker from '@/components/DatePicker.vue';
import SelectField from '@/components/SelectField.vue';

import channel from '@/helpers/const/channel';

import {ErrorResponse} from '@/types/Errors';

export default Vue.extend({
        name: 'Statistics',
        data() {
          const today = new Date();
          let year = today.getFullYear();
          const monthCalc = today.getMonth() + 1;
          let month = ('0' + (monthCalc)).slice(-2);
          const day = '01';
          let secondYear = year;
          let secondMonth = ('0' + (monthCalc)).slice(-2);
          const secondDay = new Date(year, monthCalc, 0).getDate()

            if (month === '12') {
                secondYear += 1;
                secondMonth = '01';
            }

            let first = '';
            let second = '';

            first += `${year}-${month}-${day}`;
            second += `${secondYear}-${secondMonth}-${secondDay}`;

            return {
                chart: {},
                table: [] as any,
                tableTotal: {} as any,
                projectsMap: new Map(),
                projects: [],
                partners: [],
                options: {
                    status: {
                        new: 'Первичники',
                        old: 'Повторники'
                    },
                    source: {
                        all: 'Все',
                        new_profile: 'Новые',
                        old_profile: 'Старые'
                    },
                    channel
                },
                params: {
                    from: first,
                    to: second,
                    status: 'new',
                    source: 'all',
                    project_id: 0,
                    partner_code: 'all',
                    channel: null
                },
                error: '',
                request: false,
                datesError: false,
                datesWatcher: () => { return; },
                oneDay: false
            };
        },
        created() {
            this.getFilters().then(this.getResults);
        },
        watch: {
            'projects'(val: any) {
                if (val.length === 0) {
                    return;
                }

                this.params.project_id = val[0].id;

                this.projectsMap = new Map(val.map((element: any) => {
                    return [element.id, element.partners];
                }));
            },
            'params.project_id'(val: any) {
                this.params.partner_code = 'all';
                this.partners = this.projectsMap.get(val);
            }
        },
        methods: {
            validate() {
                const fromArr = this.params.from.split('-');
                const toArr = this.params.to.split('-');

                let valid = true;

                if ((fromArr[0] > toArr[0]) ||
                    (fromArr[0] === toArr[0] && fromArr[1] > toArr[1]) ||
                    (fromArr[0] === toArr[0] && fromArr[1] === toArr[1] && fromArr[2] > toArr[2])) {
                    valid = false;
                }

                if (valid) {
                    this.getResults();
                } else {
                    this.datesErrorActivate();
                }
            },
            datesErrorActivate() {
                this.datesError = true;
                this.datesWatcher = this.$watch('params', () => {
                    this.datesError = false;
                    this.datesWatcher();
                }, {
                    deep: true
                });
            },
            getFilters() {
                return new Promise((resolve) => {
                    this.projects = this.$store.state.filters.projects;
                    resolve();
                });
            },
            getResults() {
                if (!this.request) {
                    this.request = true;
                    this.error = '';

                    this.table = [];
                    this.chart = [];

                    this.oneDay = false;

                    RCP({
                        method: 'Data.general',
                        params: this.params,
                        id: 'getResults'
                    }).then((result: any) => {
                        if (this.params.from === this.params.to) {
                            this.oneDay = true;
                        }

                        this.chart = result.chart;
                        this.table = result.table;

                        this.tableTotal = result.sum as any[];
                        this.request = false;

                        // this.calculateCR()

                    }).catch((error: ErrorResponse) => {
                        this.error = error.message;
                        this.request = false;
                    });
                }
            },
          calculateCR() {
            let totalCR = 0;
            for (let i = 0; i < this.table.length; i++) {
              const currClicks = this.table[i].clicks
              const currRegs = this.table[i].registrations
              const currCR = (((currRegs / currClicks) === Infinity ? currRegs : (currRegs / currClicks)) * 100)
              this.table[i].cr = currCR
              totalCR += currCR
            }

            this.tableTotal.cr = (totalCR / this.table.length);

          }
        },
        components: {
            ViewChart,
            StatisticsTable,
            DatePicker,
            SelectField
        }
    });
